<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-1">
              <div class="col-12 section_title">CARGA DE REMITO RECIBIDO</div>
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="GUARDAR"
              icon="pi pi-save"
              class="p-button-success mr-2"
              @click="save()"
            />
          </template>
        </Toolbar>


        <div class=""> 
          <div class="formgrid grid">
            <div class="field col-3">
 
                <label for="base">N° de Orden de Compra</label>


                <AutoComplete
                  v-model="selectedBudget"
                  :suggestions="filteredBudgets"
                  @complete="searchBudgets($event)"
                  field="id"
                  :dropdown="true"
                  @item-select="selectBudget"
                  @change="selectBudget"
                  :forceSelection="true"
                  id="base"
                  class="inputfield w-full"
                >

                 <template #item="slotProps">
                    <div class="flex">
                         <div class="ml-2">{{slotProps.item.fecha }}</div>
                        <div class="ml-2 font-bold">#{{slotProps.item.id}}</div>
                        <div class="ml-auto">{{slotProps.item.provider_name}}</div>
                    </div>
                </template>

                </AutoComplete> 
 

            </div>
            <div class="field col-3">

              <label for="base">N° de Remito</label>
              <InputText
                v-model="nro_remito"
                id="base"
                class="inputfield w-full"
              />
            </div>
            <div class="field col-3">

              <label for="base">Adjunto</label>
                <!-- <FileUpload mode="basic" name="adjunto" :customUpload="true" id="adjunto" /> -->
                <input type="file" id="adjunto" name="adjunto" class="inputfield w-full" />
            </div>
              <div class="field col-2">
                <div class="field-radiobutton">
            <RadioButton inputId="fromclient0"  id="fromclient0" name="fromclient" :value=0 v-model="fromclient" />
            <label for="fromclient0">Proveedor</label>
        </div>
        <div class="field-radiobutton">
            <RadioButton inputId="fromclient1" id="fromclient1" name="fromclient" :value=1 v-model="fromclient" />
            <label for="fromclient1">Cliente</label>
        </div>
                
          <div v-if="fromclient">

            <label for="base">Clientes</label>
                <AutoComplete
                  v-model="selectedClient"
                  :suggestions="filteredClients"
                  @complete="searchClient($event)"
                  field="name"
                  :dropdown="true"
                  @item-select="newClient = selectedClient"
                  id="base"
                  class="inputfield w-full"
                />

          </div>

          <div v-else>
                <label for="base">Proveedor</label>
                <AutoComplete
                  v-model="selectedClient"
                  :suggestions="filteredProviders"
                  @complete="searchProviders($event)"
                  field="name"
                  :dropdown="true"
                  @item-select="newClient = selectedClient"
                  id="base"
                  class="inputfield w-full"
                />


              </div>


              </div>

            <div class="field col-1">
              <label for="ingreso"> Ingreso </label>
              <Calendar
                id="ingreso"
                v-model="form.fecha_ingreso"
                class="inputfield w-full"
              />
            </div> 


            <div class="field col-12">
              <label for="formu">Observación</label>
              <InputText id="formu" v-model="form.observacion" class="inputfield w-full" />
            </div>




        </div>  
        </div>  

      </div>
    </div>
    <div class="col-12">


            
      <div class="card">
        <div class="my-1">
          <div class="col-12 section_subtitle">Items <small> </small></div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col-3">
              <label for="base">Producto</label>
              <AutoComplete
                v-model="selectedProduct"
                :suggestions="filteredProducts"
                @complete="searchProduct($event)"
                field="resumed_name"
                :dropdown="true"
                :value="name"
                @item-select="newItem = null; valorToAdd = selectedProduct.last_price"
                @item-unselect="newItem = null; "
                id="base"
                class="inputfield w-full"
              > 
              <template #item="slotProps">
                    <div class="mat-item">
                        <div class="ml-2"><strong>
{{slotProps.item.material_code}}
                        </strong>
                        {{ slotProps.item.name }}
                        </div>
                    </div>
                </template>
              </AutoComplete>

            </div>
            <div class="field col-3">
                <label for="formu">Cantidad</label>
                <InputText
                 :disabled="!selectedProduct"
                  v-model="qtyToAdd"
                  id="formu"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
              </div>
              
              


        <div class="field col-2">
        
            <Button :disabled="!qtyToAdd"  @click="addItem(newItem)" label="Agregar" 
              icon="pi pi-plus" class="p-button-success inputfield w-full mt-5" />

        </div>



          </div>
        </div>

        <div class="">
          <DataTable
            :value="items"
             dataKey="id" 
            class="p-datatable-sm"
            responsiveLayout="scroll"
          >
            
            <Column header="" >
              <template #body="slotProps">
                  <Button icon="pi pi-times" class="p-button-rounded p-button-danger" @click="delItem(slotProps.data)"></Button>

                  <Button v-if="slotProps.data.plane" icon="pi pi-file-pdf" class="p-button-rounded p-button-info" @click="showPlane(plane.file)"></Button>
                  <!-- <div v-for="plane in slotProps.data.planos" :key="plane.id">
                     <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" @click="showPlane(plane.file)"></Button>
                  </div> -->
              </template>
            </Column>
                       <Column headerClass="" field="qty" header="Cantidad" headerStyle="max-width: 20%">
              <template #body="slotProps">
                <InputText 
                  v-model="slotProps.data.qty"
                  id="iqty"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
              </template>
            </Column>
           <Column headerClass="" field="material_code" header="Código Int." headerStyle="max-width: 20%">
              <template #body="slotProps">
                <InputText 
                  v-model="slotProps.data.material_code"
                  id="imaterial_code"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
              </template>
            </Column>
            <Column field="name" header="Detalle">
            <template #body="slotProps" >
              <InputText 
                  v-model="slotProps.data.name"
                  id="imp"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
            </template>
            </Column>
<Column field="unit" header="Unidad">
            <template #body="slotProps" >
              <InputText 
                  v-model="slotProps.data.unit"
                  id="imp"
                  type="text"
                  class="inputfield w-full"
                  placeholder=""
                />
            </template>
            </Column>
            <Column field="cert" header="Certificado">
            <template #body="slotProps" >
               <input type="file" id="cert" name="cert[]" class="inputfield w-full" />
            </template>
            </Column>
            
            <Column field="colada" header="Colada">
            
              <template #body="slotProps" >
            <InputText
                id="entrega"
                v-model="slotProps.data.colada"
                :modelValue="0"
                class="inputfield w-full"
              />
</template>
            </Column>

          </DataTable>

        </div>
      </div>

    </div>


  </div>
</template>

<script>
import CrudService from "./../../services/crud.service.js";


export default {
  data() {
    return {
      ocNumber: "",
      nro_remito: "",
      monedas: [{ value: 0, name: 'AR$'},{ value: 1, name: 'U$D' }],
      impuestos: [{ value: 0, name: '0%'},{ value: 0.21, name: '21%'},{ value: 0.105, name: '10.5%'}],
      routeName: null,
      clientType: {value: 0, name: 'Consumidor Final'},
      configuraciones: [],
      qtyToAdd: 1,
      valorToAdd: 1,
      selectedFdp: null,
      fromclient: 0,
      form: { 
        impuesto: {value: 0.21, name: '21%'},
        envio: {value: 0, name: 'Si'},
        moneda: {value: 0, name: 'AR$'},
        observacion: ' PRUEBA ',
        // set fecha_ingreso today dd-mm-yyyy
        fecha_ingreso: new Date().toISOString().slice(0, 10),
        

      }, 
      items: [],

      actualFormula: "",
      products: null,
      filteredProducts: null,
      newItem: null,
      selectedProduct: null,

      proveedores: null,
      filteredProviders: null,
      selectedClient: null,

      Clients: null,
      filteredClients: null,
      newItem: null,
      selectedClient: null,

      Budgets: null,
      filteredBudgets: null, 
      selectedBudget: null,

      stock: null,
      filteredStock: null, 
      

      mods: null,
      newItemMOD: null,
      filteredMod: null,
      selectedMod : null,
      actualFormulaMOD: "",
      
      modsMarco: null,
      newItemMODMarco: null,
      filteredModMarco: null,
      selectedModMarco : null,
      actualFormulaMODMarco: "",

      submitted: false,
      validationErrors: null,
 
      ciif: 0,
      mpi: 0,
      moi: 0 ,
      //calcQuantity: 0,
 
      formasdepago: [ 
      ],
      materials: [],

    };
  },
  
  // watch fromclient
  watch: {
    'fromclient' (val) {
      this.selectedClient = null;
    }
  },
  computed: {
    dolarValue() {
      switch (this.form.moneda.value) {
        case 1:
          return this.configuraciones.dolar_billete;
        case 3:
          return this.configuraciones.dolar_divisa; 
        default:
          return this.configuraciones.dolar_billete;
      }

    },
    totalItems() {
      
      let total = 0;

      this.items.forEach(item => {
        console.log(item)
        total += item.valor * item.qty;
      });

      return total;

    },
    totalMOD() {
      
      let total = 0;

      this.mod.forEach(item => {
        console.log(item)
        total += item.valor * item.qty;
      });

      return total;

    },
    calculos() {
// Costo de Produccion
// MPI
// MOI
// CIIF
// Costo de General

      let data = 
      [
        {
          name: "Subtotal",
          valor: this.totalItems
        },
       /* {
          name: "IVA",
          valor: this.mpi
        },
        {
          name: "Otros Impuestos",
          valor: this.moi
        },
        {
          name: "Total",
          valor: this.ciif
        },*/
        // {
        //   name: "Costo de General",
        //   valor:  this.totalMPD + this.totalMOD + this.mpi + this.moi + this.ciif
        // },
      ];

 
    
      if(this.form.descuento > 0) {
        // to negative
        data.push({
          name: "Descuento",
          valor: this.totalItems * (-this.form.descuento / 100)
        })
      }

      if(this.form.impuesto.value > 0) {

        let descuento = 0;
        if(this.form.descuento > 0) {
          descuento = this.totalItems * (-this.form.descuento / 100);
        }


        data.push({
          name: "Impuesto",
          valor: (this.totalItems + descuento) * this.form.impuesto.value
        })
      }

// sum all data valor
      let total = 0;
      data.forEach(item => {
        total += item.valor;
      });

      data.push({
        name: "Total",
        valor: total
      });
      return data

      
    },

  },
  created() {
    // get route name
    this.routeName = this.$route.name;
  },
  mounted() {
    CrudService.getCRUD("api/proveedor").then(
      (data) => (this.proveedores = data)
    );
    CrudService.getCRUD("api/clients").then(
      (data) => (this.clients = data)
    );


    CrudService.getCRUD("api/materiales").then(
      (data) => (this.products = data)
    );

    CrudService.getCRUD("api/compras").then(
      (data) => (this.Budgets = data)
    ).then(() => {
          if(this.$route.query.select && this.Budgets) {
            this.selectedBudget = this.Budgets.find(item => item.id == this.$route.query.select);
            this.selectBudget(this.$route.query.select)
          }
    });




  },
  methods: {
    selectBudget(budget) {

      if(!budget){
        alert('=')
      }

      this.fromclient = 0;
      if(budget.obs){

        this.form.observacion = this.selectedBudget.obs;
      }

      this.selectedClient = this.proveedores.find(item => item.id == this.selectedBudget.provider_id);


      this.items = this.selectedBudget.items
    },
    valorByClientType(valor) {
      if (this.clientType.value == 0) {
        return valor
      } else {
        return valor * 0.3 + valor
      }
    },

    costoProd(){

      let costo = 0;

      return costo;

    },
    toArs(value) {
 
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    },
    toUsd(value) {
      
      // format number to currency
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "USD",
      });

    },
    toSelectedValue(value){

      if(this.form.moneda.value == 0){
        return this.toArs(value)
      }else{
        return this.toUsd(value)
      }
      
    },
    showPlane(file) {
      
      // open window
      window.open(file, "_blank");
      
      

    },
delItem(itemId){
  //console.log(item)
  // find in this.items
  let index = this.items.findIndex(item => item.id == itemId);
  // remove item
  this.items.splice(index, 1);

  // recalculate total


}, 
      formatUnity(value) {

           switch(value) {
                case 0:
                    return "ml";
                case 1:
                    return "unidad";
                case 2:
                    return "kg";
                case 3:
                    return "mt";
                case 4:
                    return "lt";
                case 5:
                    return "m2";
                case 6:
                    return "mtl";
                case 7:
                    return "gr";
                case 8:
                    return "juego";
                default:
                    return "";
            }
      },
    displayErrors(errors) {
      for (let key in errors) {
        this.validationErrors.push({ field: key, message: errors[key] });

        let error = errors[key];
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: error[0],
          life: 3000,
        });
      }
    },
    searchStock(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredStock = [...this.stock];
        } else {
          this.filteredStock = this.stock.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchProduct(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredProducts = [...this.products];
        } else {
          this.filteredProducts = this.products.filter((prod) => {
            return prod.material_code
              .toLowerCase()
              .startsWith(event.query.toLowerCase())
              ||
              prod.name.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchClient(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredClients = [...this.clients];
        } else {
          this.filteredClients = this.clients.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchProviders(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredProviders = [...this.proveedores];
        } else {
          this.filteredProviders = this.proveedores.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    
    searchBudgets(event) {
      if(!event.query.length >= 3){
        return;
      }

      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredBudgets = [...this.Budgets];
        } else {
          this.filteredBudgets = this.Budgets.filter((prod) => {
            return prod.id == event.query || prod.provider_name.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchMDO(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredMod = [...this.mods];
        } else {
          this.filteredMod = this.mods.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    addItem() {
 
        // check if selected product lenght
        if (!this.selectedProduct.length) {
          this.items.push({
            id: this.selectedProduct.id,
            name:  this.selectedProduct.name,
            material_code: this.selectedProduct.material_code,
            qty: this.qtyToAdd,
            valor: this.valorToAdd,
            planos: this.selectedProduct.planos,
            unit: this.selectedProduct.unit ? this.selectedProduct.unit : 0,
            colada: 0,

          });
        }else{

          this.items.push({
            id: 0,
            name: this.newItem ? this.newItem : this.selectedProduct,
            qty: this.qtyToAdd,
            valor: this.valorToAdd,
          });

        }

        this.newItem = null;
        this.qtyToAdd = 1;
        this.valorToAdd = 1;
        this.selectedProduct = null;
 
    },
    save() {
      this.submitted = true;
 
      let formData = new FormData();

      formData.append("proveedor", this.selectedClient.id);

      formData.append("observacion", this.form.observacion);

      if(this.fromclient){
        formData.append("fromclient", this.selectedClient.id);
      }
      
                       
      formData.append("fecha_ingreso", this.form.fecha_ingreso);

      if(this.selectedBudget){
        //formData.append("presupuesto", this.selectBudget.id);
        formData.append("oc_number", this.selectedBudget.id);
      }
      
      formData.append("nro_remito", this.nro_remito);
      
      formData.append("items", JSON.stringify(this.items));

      //get file from input adjunto
      let file = document.getElementById("adjunto").files[0];
      formData.append("adjunto", file);


      CrudService.createCRUD("api", "remitosr", formData).then(
        (data) => {
          this.validationErrors = [];
       //   if (data.status == 400) {
       //     this.displayErrors(data.data);
       //   } else {
            this.$toast.add({
              severity: "success",
              summary: "Éxito!",
              detail: "Creada correctamente.",
              life: 3000,
          });

          this.$router.push("/remitos");

         // }
        }
      ).catch((error) => {
        console.log(error);

      });


    },
  },
};
</script>


<style scoped>

  .section_subtitle {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1261b1;
    margin-bottom: 1rem;
  }

  .card {
    padding-top: 0.25rem;
  }

</style>